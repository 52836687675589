/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript


        // ScrollReveal https://scrollrevealjs.org/
        var slideUp = {
          distance: '15%',
          origin: 'bottom',
          interval: 100,
          opacity: 0,
          duration: 700,
          delay: 500,
          useDelay: 'onload',
          // mobile: false
        };
        new ScrollReveal().reveal('.slideUp', slideUp);
        new ScrollReveal().reveal('.stack-process-reveal, .stack-team-reveal, .project-group, .client-logo-frame', slideUp);

        var slideDown = {
          distance: '20px',
          origin: 'top',
          opacity: 0,
          interval: 200,
          delay: 1500,
          duration: 400,
          useDelay: 'onload',
          // mobile: false
        };
        new ScrollReveal().reveal('.slideDown', slideDown);

        var slideRightDelay = {
          distance: '15px',
          origin: 'left',
          opacity: 0,
          interval: 100,
          delay: 1750,
          duration: 400,
          useDelay: 'onload',
          // mobile: false
        };
        new ScrollReveal().reveal('.slideRightDelay', slideRightDelay);

        var slideRight = {
          distance: '15px',
          origin: 'left',
          opacity: 0,
          interval: 200,
          duration: 400,
          // mobile: false
        };
        new ScrollReveal().reveal('.slideRight', slideRight);



        // Expanding Grid i.e. Service Cards
        $('.expanding-grid').on('click', '.toggle-cell', function () {
          $('.expanding-grid .toggle-cell.active').not(this).removeClass('active');
          $(this).addClass('active');
          $('.expanding-grid .expanding-card.show').not(this).removeClass('show');
          $(this).next('.expanding-card').addClass('show');
          $('html, body').animate({
            scrollTop: $(this).offset().top
          }, 150);
        });

        $('.expanding-grid').on('click', '.close-button', function () {
          $(this).parent('.expanding-card').removeClass('show');
          $('.toggle-cell.active').removeClass('active');
        });


        // CounterUp
        $('.counter').counterUp({
          time: 1000
        });


        // Testimonial Slider
        $('.testimonial-slider').slick({
          dots: false,
          infinite: true,
          fade: true,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnFocus: true,
          adaptiveHeight: true
        });

        // Function from David Walsh: http://davidwalsh.name/css-animation-callback
        function whichTransitionEvent() {
          var t,
              el = document.createElement("fakeelement");

          var transitions = {
            "transition"      : "transitionend",
            "OTransition"     : "oTransitionEnd",
            "MozTransition"   : "transitionend",
            "WebkitTransition": "webkitTransitionEnd"
          };

          for (t in transitions){
            if (el.style[t] !== undefined){
              return transitions[t];
            }
          }
        }

        var transitionEvent = whichTransitionEvent();

        $(window).on('load', function() {
          $('#loading-overlay').addClass('loaded');

          $('#loading-overlay').one(transitionEvent, function() {
            $(this).css('z-index', -1);

            // Animate text
            $('.card-heading-frame .card-heading').addClass('slideRightDelay');
            $('.card-heading-frame .card-entry').addClass('slideRightDelay');
            new ScrollReveal().reveal('.slideRightDelay', slideRightDelay);

            setTimeout(function() {
              /* Start SVG animation */
              $('.svg-mask-frame').addClass('loaded');

              /* ... and when it ends, start the slider */
              $('.svg-mask-frame.loaded #masked').one("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function() {
                $('.homepage-slider').slick('slickNext').slick('slickPlay');

                $('#loading-overlay').remove();
                $(this).parent().remove();
              });
            }, 3500);
          });
        });

        // Homepage Slider
        $('.homepage-slider').slick({
          dots: false,
          arrows: false,
          infinite: true,
          fade: true,
          autoplay: false,
          autoplaySpeed: 5000,
          speed: 1000
        });

        /* Deep Linking Hash Change */
        // var hash = window.location.hash;
        // if (window.location.href.indexOf(hash) !== -1) {
        //   $(hash).addClass('active');
        //   $(hash).next('.expanding-card').addClass('show');
        // }

        // function hashChange() {
        //   var hash = window.location.hash;
        //   if (window.location.href.indexOf(hash) !== -1) {
        //     $('.expanding-grid .toggle-cell.active').removeClass('active');
        //     $(hash).addClass('active');
        //     $('.expanding-card.show').removeClass('show');
        //     $(hash).next('.expanding-card').addClass('show');
        //   }
        // }

        // window.onhashchange = function() {
        //   hashChange();
        // };


        // Header Nav
        var $document = $(document),
          $siteHeader = $('.site-header');

        $document.scroll(function () {
          if ($document.scrollTop() >= 5) {
            $siteHeader.addClass('stickynav');
          } else {
            $siteHeader.removeClass('stickynav');
          }
        });

        // Sticker
        if ($('.sticker').length) {
          var s = $('.sticker');
          var pos = s.position();
          $(document).scroll(function () {
            var windowpos = $(document).scrollTop();
            if (windowpos >= pos.top) {
              s.addClass('stuck');
            } else {
              s.removeClass('stuck');
            }
          });
        }

        // Scroll Event for Navigation - http://jsfiddle.net/designaroni/sj3euzL7/
        var position = $(window).scrollTop();

        $(window).scroll(function () {
          var scroll = $(window).scrollTop();
          if (scroll < position) {
            // console.log('scrollUp');
            $('.stack--smooth-scroll-tabs').addClass('scroll-up');
          } else {
            // console.log('scrollDown');
            $('.stack--smooth-scroll-tabs').removeClass('scroll-up');
          }
          position = scroll;
        });


        // Deeplink via Hash with Offset
        // if ( window.location.hash ) {
        //   var hash = window.location.hash,
        //       $hash = $(hash);

        //   $hash.removeAttr('id');
        //   $hash.before('<div id="'+hash.slice(1)+'" class="hashlink"></div>');
        //   window.location.hash = hash;
        // }

        // In-Page Scroll Animation
        // ------------------------
        // $('a[href^="#"]').on('click', function(e) {
        //     var hash  = this.hash,
        //       $hash = $(hash),
        //         addHash = function() {
        //             window.location.hash = hash;
        //         };

        //     if ( hash !== '#header' ) {
        //         // $hash.velocity('scroll', { duration: 500, offset: -50, complete: addHash }); // Velocity.js
        //         $('html,body').animate({ 'scrollTop': $hash.offset().top -300 }, 500, addHash);
        //     } else {
        //         // $hash.velocity('scroll', { duration: 500, offset: 0, complete: addHash }); // Velocity.js
        //         $('html,body').animate({ 'scrollTop': $hash.offset().top }, 500, addHash);
        //     }
        //     e.preventDefault();
        // });



        // Smooth Scroll + Highlight Sticky Nav

        // $sections incleudes all of the container divs that relate to menu items.
        var $sections = $('.stack');

        // The user scrolls
        $(window).scroll(function () {

          // currentScroll is the number of pixels the window has been scrolled
          var currentScroll = $(this).scrollTop();

          // $currentSection is somewhere to place the section we must be looking at
          var $currentSection;

          // We check the position of each of the divs compared to the windows scroll positon
          $sections.each(function () {
            // divPosition is the position down the page in px of the current section we are testing
            var divPosition = $(this).offset().top - 120;

            // If the divPosition is less the the currentScroll position the div we are testing has moved above the window edge.
            // the -1 is so that it includes the div 1px before the div leave the top of the window.
            if (divPosition + 1 < currentScroll) {
              // We have either read the section or are currently reading the section so we'll call it our current section
              $currentSection = $(this);

              // If the next div has also been read or we are currently reading it we will overwrite this value again. This will leave us with the LAST div that passed.
            }

            // This is the bit of code that uses the currentSection as its source of ID
            var id = $currentSection.attr('id');
            $('a.scroll-link').removeClass('active');
            $("[href=#" + id + "]").addClass('active');

          });

        });






      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function () {
        // JavaScript to be fired on the about us page

        $("path.st2").hover(function (e) {
          $('#info-box').css('display', 'block');
          $('.info-entry').html($(this).data('info'));
        });

        $("path.st2").mouseleave(function (e) {
          $('#info-box').css('display', 'none');
        });

        $("path.st2").click(function () {
          $dataUrl = $(this).data('url');
          window.location = $dataUrl;
        });

        $(document).mousemove(function (e) {
          $('#info-box').css('top', e.pageY - $('#info-box').height() - 8);
          $('#info-box').css('left', e.pageX - ($('#info-box').width()) / 1.70);
        }).mouseover();



      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
